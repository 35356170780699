@font-face {
  font-family: 'Arboria';
  font-style: normal;
  font-weight: 900;
  src: local('Arboria-Black'), local('Arboria'),
    url('./assets/fonts/Arboria/Arboria-Black.woff2') format('woff2'),
    url('./assets/fonts/Arboria/Arboria-Black.woff') format('woff'),
    url('./assets/fonts/Arboria/Arboria-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: italic;
  font-weight: 900;
  src: local('Arboria-BlackItalic'), local('Arboria'),
    url('./assets/fonts/Arboria/Arboria-BlackItalic.woff2') format('woff2'),
    url('./assets/fonts/Arboria/Arboria-BlackItalic.woff') format('woff'),
    url('./assets/fonts/Arboria/Arboria-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: normal;
  font-weight: 700;
  src: local('Arboria-Bold'), local('Arboria'),
    url('./assets/fonts/Arboria/Arboria-Bold.woff2') format('woff2'),
    url('./assets/fonts/Arboria/Arboria-Bold.woff') format('woff'),
    url('./assets/fonts/Arboria/Arboria-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: italic;
  font-weight: 700;
  src: local('Arboria-BoldItalic'), local('Arboria'),
    url('./assets/fonts/Arboria/Arboria-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/Arboria/Arboria-BoldItalic.woff') format('woff'),
    url('./assets/fonts/Arboria/Arboria-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: normal;
  font-weight: 400;
  src: local('Arboria-Book'), local('Arboria'),
    url('./assets/fonts/Arboria/Arboria-Book.woff2') format('woff2'),
    url('./assets/fonts/Arboria/Arboria-Book.woff') format('woff'),
    url('./assets/fonts/Arboria/Arboria-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: italic;
  font-weight: 400;
  src: local('Arboria-BookItalic'), local('Arboria'),
    url('./assets/fonts/Arboria/Arboria-BookItalic.woff2') format('woff2'),
    url('./assets/fonts/Arboria/Arboria-BookItalic.woff') format('woff'),
    url('./assets/fonts/Arboria/Arboria-BookItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: normal;
  font-weight: 300;
  src: local('Arboria-Light'), local('Arboria'),
    url('./assets/fonts/Arboria/Arboria-Light.woff2') format('woff2'),
    url('./assets/fonts/Arboria/Arboria-Light.woff') format('woff'),
    url('./assets/fonts/Arboria/Arboria-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: italic;
  font-weight: 300;
  src: local('Arboria-LightItalic'), local('Arboria'),
    url('./assets/fonts/Arboria/Arboria-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/Arboria/Arboria-LightItalic.woff') format('woff'),
    url('./assets/fonts/Arboria/Arboria-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Arboria-Thin';
  font-style: normal;
  font-weight: 100;
  src: local('Arboria-Thin'), local('Arboria'),
    url('./assets/fonts/Arboria/Arboria-Thin.woff2') format('woff2'),
    url('./assets/fonts/Arboria/Arboria-Thin.woff') format('woff'),
    url('./assets/fonts/Arboria/Arboria-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: italic;
  font-weight: 100;
  src: local('Arboria-ThinItalic'), local('Arboria'),
    url('./assets/fonts/Arboria/Arboria-ThinItalic.woff2') format('woff2'),
    url('./assets/fonts/Arboria/Arboria-ThinItalic.woff') format('woff'),
    url('./assets/fonts/Arboria/Arboria-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: normal;
  font-weight: 500;
  src: local('Arboria-Medium'), local('Arboria'),
    url('./assets/fonts/Arboria/Arboria-Medium.woff2') format('woff2'),
    url('./assets/fonts/Arboria/Arboria-Medium.woff') format('woff'),
    url('./assets/fonts/Arboria/Arboria-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: italic;
  font-weight: 500;
  src: local('Arboria-MediumItalic'), local('Arboria'),
    url('./assets/fonts/Arboria/Arboria-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/Arboria/Arboria-MediumItalic.woff') format('woff'),
    url('./assets/fonts/Arboria/Arboria-MediumItalic.ttf') format('truetype');
}
